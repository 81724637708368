<template>
    <mercur-card class="fill full-height-layout mb-3 mx-4">
        <grid-header :quickSearch.sync="filters.search">
            Product Template Propagation
            <div class="d-flex align-items-center" slot="filters">
                <div class="ml-4 mr-3">
                    <pretty-select
                    class="productDropDown"
                    placeholder="Product Template"
                    id="productConfigurationTemplate"
                    :multiple="false"
                    v-model="filters.productConfigurationTemplate"
                    v-on:keypress.enter.prevent
                    :options="productDropDown"
                    label="Product Template"
                ></pretty-select>
                </div>
                <div class="ml-4">
                    <mercur-select v-model="filters.status">
                        <template slot="label">Status</template>
                        <option value="null">All</option>
                        <option value="PENDING">Pending</option>
                        <option value="PROPAGATING">Propagating</option>
                        <option value="DONE">DONE</option>
                        <option value="FAILED">Failed</option>
                    </mercur-select>
                </div>
            </div>
        </grid-header>

        <div class="border fill full-height-layout mt-2" >
            <data-table
                class="fill full-height-layout border"
                ref="grid"
                :url="url"
                :options="options"
                :quickSearch="filters.search"
            ></data-table>
        </div>

          <mercur-dialog width="900px" :is-open.sync="showAggregateInfoOpen">
            <grid-header>
                Aggregate Details
            </grid-header>

            <div class="border fill full-height-layout mt-2" >
                <data-table
                    class="fill full-height-layout border"
                    ref="gridAggregate"
                    :items="aggregateDetails"
                    :options="aggregateOptions"
                ></data-table>
            </div>
        </mercur-dialog>
    </mercur-card>
</template>

<script>
import DataTable from '@/components/DataTable'
import CONFIG from '@root/config'
import GridHeader from '@/components/GridHeader'
import PrettySelect from '@/components/utils/PrettySelect'

export default {
    name: 'ProductTemplatePropagation',
    components: { DataTable, GridHeader, PrettySelect },
    data () {
        return {
            filters: {
                search: '',
                productConfigurationTemplate: 'All',
                status: 'null',
            },
            productTemplates: [],
            productDropDown: [],
            showAggregateInfoOpen: false,
            options: {
                columns: {
                    'Id': { field: 'productTemplateChangePropagationId' },
                    'ProductConfigurationTemplateId': { field: 'productConfigurationTemplateId', hide: true },
                    'Configuration Template Name': { field: 'productConfigurationTemplateName', sortable: true },
                    'Aggregate Id': { field: 'aggregateId' },
                    'Status': { field: 'status', sortable: true },
                    'Created': { field: 'dateCreated', sortable: true },
                },
                actions: [
                    {
                        text: 'Details',
                        icon: 'fas fa-expand',
                        onClick: ({ data }) => {
                            this.showAggregateInfo(data.aggregateId)
                        },
                    },
                    {
                        text: 'Publish',
                        icon: 'fas fa-upload',
                        hide: (data) => {
                            const val = data.data.status !== 'PENDING'
                            return val
                        },
                        onClick: ({ data }) => {
                            this.propagateProductTemplateChanges(data.productTemplateChangePropagationId)
                        },
                    },
                ],
            },
            aggregateOptions: {
                columns: {
                    'Aggregate Id': { field: 'aggregateId' },
                    'Configuration Name': { field: 'productConfigurationName', sortable: true },
                    'Status': { field: 'status', sortable: true },
                    'Created': { field: 'dateCreated', sortable: true },
                },
            },
            aggregateDetails: [],
            url: CONFIG.API.ROUTES.SUPPLIERS.TEMPLATE_PROPAGATION.LIST.replace('{supplierId}', this.supplierId).replace('{changeType}', 'PRODUCT_UPDATE'),
        }
    },
    computed: {
        filteredItems () {
            return this.items
        },
    },
    watch: {
        'filters.search' () {
            this.$refs.grid.gridApi.setFilterModel(this.generateFilterModel())
        },
        'filters.status' () {
            this.$refs.grid.gridApi.setFilterModel(this.generateFilterModel())
        },
        'filters.productConfigurationTemplate' () {
            this.$refs.grid.gridApi.setFilterModel(this.generateFilterModel())
        },
    },
    methods: {
        generateFilterModel () {
            let model = {
                productConfigurationTemplateName: {
                    type: 'contains',
                    filter: this.filters.search,
                    filterType: 'text',
                },
            }

            if (this.filters.status && this.filters.status !== 'null' && this.filters.status !== 'All') {
                model.status = {
                    type: 'equals',
                    filter: this.filters.status,
                    filterType: 'text',
                }
            }

            if (this.filters.productConfigurationTemplate && this.filters.productConfigurationTemplate !== 'null' && this.filters.productConfigurationTemplate !== 'All') {
                model.productConfigurationTemplateId = {
                    type: 'equals',
                    filter: this.productTemplates.find((item) => item.productConfigurationTemplateName === this.filters.productConfigurationTemplate).productConfigurationTemplateId,
                    filterType: 'text',
                }
            }
            if (this.filters.productConfigurationTemplate === null) {
                this.filters.productConfigurationTemplate = 'All'
            }
            return model
        },
        showAggregateInfo (aggregateId) {
            this.showAggregateInfoOpen = true
            const url = CONFIG.API.ROUTES.SUPPLIERS.TEMPLATE_PROPAGATION.AGGREGATELIST.replace('{supplierId}', this.supplierId).replace('{aggregateId}', aggregateId)
            this.addJob(url)
            this.$api.post(url, CONFIG.API.REQUEST_DEFAULT).then(({ data }) => {
                this.aggregateDetails = data.data.items
            }).catch(data => {
                this.$root.$emit('notification:global', {
                    message: `Propagate Product Template Changes failed. Please try again.`,
                    type: 'error',
                    errors: data,
                })
                this.showAggregateInfoOpen = false
            }).finally(() => {
                this.finishJob(url)
            })
        },
        propagateProductTemplateChanges (productTemplateChangePropagationId) {
            const url = CONFIG.API.ROUTES.SUPPLIERS.TEMPLATE_PROPAGATION.PROPAGATE_PRODUCT_TEMPLATE_CHANGES.replace('{supplierId}', this.supplierId).replace('{productTemplateChangePropagationId}', productTemplateChangePropagationId)
            this.addJob(url)
            this.$api.post(url).then(({ data }) => {
                this.$root.$emit('notification:global', {
                    message: `Propagate Product Template Changes done successfully.`,
                })
                this.$refs.grid.refreshGrid()
            }).catch(data => {
                this.$root.$emit('notification:global', {
                    message: `Propagate Product Template Changes failed. Please try again.`,
                    type: 'error',
                    errors: data,
                })
                this.showAggregateInfoOpen = false
            }).finally(() => {
                this.finishJob(url)
            })
        },
        getProductTemplates () {
            const url = CONFIG.API.ROUTES.HS_CODES.GET_AVAILABLE_PRODUCT_TEMPLATES
            this.addJob(url)
            this.$api.post(url, CONFIG.API.REQUEST_DEFAULT).then(({ data }) => {
                this.productTemplates = data.data.items
                this.productDropDown = this.productTemplates.map((item) => {
                    return item.productConfigurationTemplateName
                })
                this.productDropDown.push('All')
            }).catch(data => {
                this.$root.$emit('notification:global', {
                    message: `Error getting ProductTemplates`,
                    type: 'error',
                    errors: data,
                })
            }).finally(() => {
                this.finishJob(url)
            })
        },
    },
    created () {
        this.getProductTemplates()
    },
}
</script>

<style lang="scss" scoped>
 /deep/ .filters {
     display: flex;
     flex-wrap: wrap;
     align-items: baseline;
 }
 .productDropDown{
     min-width: 200px;
 }
</style>
